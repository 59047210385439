import * as React from "react";

function SvgRags(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M1349.73 490l-108 215.54a30 30 0 01-40.5 13.56l-106.64-52.19c-20-9.79-43.34 4.9-43.34 27.13v478a60.22 60.22 0 01-60.25 60.28H508.69A60.22 60.22 0 01448.4 1172V694.23a30.1 30.1 0 00-43.34-27.13l-106.63 52.19a30.36 30.36 0 01-40.51-13.57L150.15 490a30.37 30.37 0 0113.56-40.51l366.83-181.81c37.87 52.38 121.71 88.93 219.49 88.93s181.63-36.55 219.5-88.93l366.82 181.81a30.08 30.08 0 0113.38 40.51z" />
    </svg>
  );
}

export default SvgRags;

import * as React from "react";

function SvgCoats(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M728.33 1210.2h-250.4a49.16 49.16 0 01-39.62-20V664.19a19.28 19.28 0 10-38.55 0v392.08a25 25 0 01-15.85 5.61H227a25.43 25.43 0 01-25.4-25.41V412a26 26 0 01.26-3.6 9.84 9.84 0 00.06-2.3 19.89 19.89 0 0110.26-19.42l294.64-93-48.27 201.74a19.28 19.28 0 0014.68 23.32l101 21.83-11.81 62.74a19.16 19.16 0 004.36 16.17l161.5 186.85zm17.93-442.06L602.07 601.31l13.56-72a19.16 19.16 0 00-14.87-22.41l-100.13-21.68 41.24-172.32C599.73 414.72 735.49 691.25 737 694.26a9.73 9.73 0 008.73 5.44 9.74 9.74 0 008.73-5.45c1.47-3 137.56-280.67 195.16-382l42.52 172.58-100 21.6a19.16 19.16 0 00-14.87 22.4l13.56 72zm527.13 292l-155.61 3.36a25.19 25.19 0 01-16.61-5.86V664.19a19.28 19.28 0 10-38.55 0v527.67a49.36 49.36 0 01-38.36 18.34H766.88v-407L926 619.09a19.19 19.19 0 004.36-16.17l-11.81-62.74 101-21.83a19.26 19.26 0 0014.64-23.45l-49.44-201.06 293 92.89A19.65 19.65 0 011288 403a9.56 9.56 0 00.24 1.69 25 25 0 01.67 5.35l9.53 624.35a25.43 25.43 0 01-25.05 25.79z" />
    </svg>
  );
}

export default SvgCoats;

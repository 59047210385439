import * as React from "react";

function SvgDecoratives(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M879.32 297.67l128.75 297.71H491.93l128.75-297.71zm-496.8 0H531L402.24 595.38H214.13zm238.54 831.71L214.13 654.92H401.5l228.86 468.32c2.79 5.76-5.02 10.98-9.3 6.14zM745 1247L489.88 654.92h520.24L755.21 1247a5.58 5.58 0 01-10.21 0zm134-117.78c-4.28 5-12.09-.19-9.3-6l228.8-468.3h187.37zm218.82-533.82L969 297.67h148.48l168.39 297.71z" />
    </svg>
  );
}

export default SvgDecoratives;

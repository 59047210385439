import { traitDefinitions, Trait } from 'shared';

function isDevelopment() {
  return true //process.env.NODE_ENV === 'development'
}

function randomIntFromInterval(min: number, max: number): number { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// take random element from array
function sample<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

function getAllPossibleTraits(current_traits: Trait[]): Trait[] {
  function onlyUnique(value: Trait, index:number, self: Trait[]) {
    return self.findIndex(t => t.trait_id === value.trait_id) === index;
  }
  return current_traits
    .flatMap(t => getPossibleTraits(t.trait_id, current_traits))
    .filter(onlyUnique)
}

// returns the traits with dependency_ids containing parent_trait_id
// which are not excluded
function getPossibleTraits(parent_trait_id: string, chosenTraits: Trait[]): Trait[] {
  let already_excluded = chosenTraits.flatMap(t => t.exclude_ids)
  return traitDefinitions
    .filter(t => t.dependency_ids.includes(parent_trait_id))
    // filter the exclude_ids we already have
    .filter(t =>
      // intersection between myExcludIds and t.exclude_ids
      t.exclude_ids.filter(meid => already_excluded.includes(meid)).length === 0
    ).concat(
      traitDefinitions
        // show the multi dependency_ids that have
        // the parent_trait_id included too but leave the ones without multi dependency_ids
        .filter(t => {
          return t.dependency_ids
            .filter(e => e instanceof Array)
            .some((arrayElement: any) => {
              let isInMyTraitIds = (el: string) =>
                chosenTraits.map(t => t.trait_id).includes(el)
              return arrayElement.every(isInMyTraitIds)
            })
        }
        )
        // exclude the ones we have
        .filter(t =>
          // intersection between myExcludIds and t.exclude_ids
          t.exclude_ids.filter(meid => already_excluded.includes(meid)).length === 0
        )
    ).filter(t =>
      !(parent_trait_id === "torso" && already_excluded.includes("penis2") && t.exclude_ids.includes("pants"))
    )
}



function generatePermutation() {
  const samples = randomIntFromInterval(0, 25);
  const initialEmpty = [traitDefinitions[0] as Trait]
  let result: Trait[] = initialEmpty

  let randomFromCategory = (cat: string) =>
    sample(getAllPossibleTraits(result).filter(t => t.exclude_ids[0] === cat))

  // everybody gets these
  result.push(randomFromCategory('canvas'))
  result.push(randomFromCategory('torso'))
  result.push(randomFromCategory('head'))
  result.push(randomFromCategory('face'))

  // most have 4 extremities
  if (Math.random() < 0.9) {
    result.push(randomFromCategory('arm rechts'))
    result.push(randomFromCategory('arm links'))
    result.push(randomFromCategory('leg right'))
    result.push(randomFromCategory('leg left'))
  }

  for (let i = 0; i < samples; i++) {
    let newTrait = sample(
      getAllPossibleTraits(result)
        .filter(t => !t.exclude_ids.includes("gown"))
        .filter(t => !t.exclude_ids.includes("tshirt"))
        .filter(t => !t.exclude_ids.includes("swimsuit"))
        .filter(t => !t.exclude_ids.includes("karton"))
        .filter(t => !t.exclude_ids.includes("stola"))
        .filter(t => !t.exclude_ids.includes("animal"))
    )


    if (!newTrait) continue;
    result.push(newTrait)
  }

  // some get an animal
  if (Math.random() < 0.05) {
    let newTrait = sample(
      getAllPossibleTraits(result)
        .filter(t =>
          t.exclude_ids.includes("animal")
        )
    )
    result.push(newTrait)
  }

  // low percentage items
  if (Math.random() < 0.3) {
    let newTrait = sample(
      getAllPossibleTraits(result)
        .filter(t =>
          t.exclude_ids.includes("gown") ||
          t.exclude_ids.includes("tshirt") ||
          t.exclude_ids.includes("swimsuit") ||
          t.exclude_ids.includes("karton") ||
          t.exclude_ids.includes("stola")
        )
    )
    if (newTrait)
      result.push(newTrait)
  }
  return result;
}


export {
  getAllPossibleTraits,
  generatePermutation,
  sample,
  isDevelopment
}

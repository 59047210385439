import React from "react";
import {getFingerprint, getTraits, StepGroup, stepGroups, Trait, traitDefinitions, toggleTrait, deleteRecursively} from 'shared';
import Character from "./Character";
import {generatePermutation, getAllPossibleTraits, isDevelopment, sample} from "./trait_utils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRandom, faSync, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import Web3 from "web3"
import {toSimpleTrait} from "shared/src/trait_definitions";
import {
  Backgrounds,
  Bodyparts,
  Coats,
  Decoratives,
  Face,
  FaceAttachment,
  Fun,
  Hands,
  Hats,
  Pants,
  Rags,
  Sex,
  Shoes,
  Slips,
  Torso
} from './Icons'
import {faEthereum} from "@fortawesome/free-brands-svg-icons";

const INITIAL_MYTRAITS: Trait[] = [traitDefinitions[0]] // add empty root

const MINT_PRICE = 100000000000000000n.toString()

const MAX_TRAITS = 40

const svgs: {[key: string]: any} = {
  "Backgrounds": Backgrounds,
  "Bodyparts": Bodyparts,
  "Coats": Coats,
  "Decoratives": Decoratives,
  "Face": Face,
  "FaceAttachment": FaceAttachment,
  "Fun": Fun,
  "Hands": Hands,
  "Hats": Hats,
  "Pants": Pants,
  "Rags": Rags,
  "Sex": Sex,
  "Shoes": Shoes,
  "Slips": Slips,
  "Torso": Torso,
}


function getIconPath(trait: Trait) {
  return trait.path.slice(0, -4) + "_ico.png"
}

type TraitEditorProps = {
  showPopup: () => void,
  myTraits: Trait[],
  updateMyTraits: (traits:Trait[]) => void
}

type TraitEditorState = {
  expandedStepIndex: number,
  selectedGroup: StepGroup,
}

class TraitEditor extends React.Component<TraitEditorProps, TraitEditorState> {
  allTraits = traitDefinitions

  child : React.RefObject<Character> = React.createRef()

  constructor(props: TraitEditorProps) {
    super(props);
    this.state = {
      expandedStepIndex: 0,
      selectedGroup: stepGroups[0],
    }

  }

  mint() {
    let logResultOrError = (err:any, result: any) => {
      if (err) console.error(err)
      else console.log(result.toString())
    }

    let contract = (window as any).contract;
    let web3: Web3 = (window as any).web3;

    contract.methods.totalSupply().call(logResultOrError)
      .then(() =>
        contract.methods.purchase(getFingerprint(this.props.myTraits.map(toSimpleTrait)).toString())
          .send({
            from: web3.defaultAccount,
            value: MINT_PRICE
          }, logResultOrError)
      ).catch((error: any) => {
        console.error(error)
        alert("Something went wrong when minting:\n" + error.message)
      })
      .then(() =>
        contract.methods.totalSupply().call(logResultOrError)
      )
  }

  clear() {
    this.props.updateMyTraits(INITIAL_MYTRAITS)
  }

  randomize() {
    this.props.updateMyTraits(generatePermutation())
  }

  deleteFromMyTraits(trait_id: string) {
    this.props.updateMyTraits(deleteRecursively(this.props.myTraits, trait_id))
  }

  getTraitsForGroup(exclude_ids: string[], traits: Trait[]) {
    return traits
      .filter(t => exclude_ids.includes(t.exclude_ids[0]))
  }

  toggleTrait(trait: Trait, isPossible: boolean) {
    const isIn = this.props.myTraits.some(t => t.trait_id === trait.trait_id)
    const isAdd = !isIn && isPossible;
    if (!(isAdd && this.hasReachedMaxTraits()))
        this.props.updateMyTraits(toggleTrait(trait, this.props.myTraits))
  }

  renderCharacter(onClick: () => void) {
    if(this.props.myTraits.length > INITIAL_MYTRAITS.length) {
      return (
        <div className="character-wrapper" onClick={()=> onClick()} title="Click to show selected traits and fingerprint">
          <Character ref={this.child} traits={this.props.myTraits} showLayerSlider={true} showFingerprint={false}/>
        </div>
      )
    } else {
      return <div className="character-empty">Start creating your character with the menu on the right.</div>
    }
  }

  addRandomPossibleTrait() {
    let newTrait = sample(getAllPossibleTraits(this.props.myTraits))
    this.props.updateMyTraits(newTrait ? this.props.myTraits.concat(newTrait) : this.props.myTraits)
  }

  selectGroup(group: StepGroup) {
    this.setState({selectedGroup: group})
  }

  isSelected(trait: Trait) {
    return this.props.myTraits.some((t) => t.trait_id === trait.trait_id)
  }

  hasReachedMaxTraits() {
    return this.props.myTraits.length >= MAX_TRAITS+1
  }

  renderGroupTrait(possibleTraits:Trait[], group: StepGroup, trait: Trait) {
    const overflowHidden = group.exclude_ids[0] === "canvas"
    const isSelected = this.isSelected(trait)
    const isPossible: boolean = possibleTraits.some((p:Trait) => trait.trait_id === p.trait_id)

    let previewClass = "trait-preview" +
      ( overflowHidden ? " cropped" : "")
    let clickAction = isSelected ? "Remove" : (isPossible ? "Add" : "Switch to")
    let title = !isDevelopment() ? clickAction + " " + trait.name : JSON.stringify(trait, null, 2)
    const traitClass = "trait-preview-wrapper" + ( this.hasReachedMaxTraits() ? " max-reached" : "") +
        ( isSelected ? " selected" : "") +
        ( isPossible ? " possible" : " impossible")
    return (
      <div key={trait.trait_id} className={traitClass}>
        <div className={previewClass} onClick={()=>this.toggleTrait(trait, isPossible)}>
          <img src={getIconPath(trait)} alt="" title={title} />
          <div className="trait-action" />
          <div className="max-traits">
            Maximum number of traits <br/>selected.
          </div>
        </div>
        <div className="trait-name">
          {trait.name}
        </div>
      </div>
    );
  }

  renderGroupTraits(group:StepGroup) {
    let myTraitsOfGroup = this.getTraitsForGroup(group.exclude_ids,this.props.myTraits)
    let possibleTraits = this.getTraitsForGroup(group.exclude_ids, getAllPossibleTraits(this.props.myTraits));
    let myTraitsWithoutThisGroup = this.props.myTraits
      .filter(t => !myTraitsOfGroup.some(tg => tg.trait_id === t.trait_id));
    let traits = this.getTraitsForGroup(group.exclude_ids, getAllPossibleTraits(myTraitsWithoutThisGroup))

    function onlyUnique(value:Trait, index: number, self: Trait[]) {
      return self.findIndex(t => t.trait_id === value.trait_id) === index;
    }

    let extraPossible = myTraitsOfGroup.flatMap(t =>
      this.getTraitsForGroup(group.exclude_ids, getAllPossibleTraits(myTraitsWithoutThisGroup.concat(t)))
    )

    let finalTraits = traits.concat(extraPossible)
        .filter(onlyUnique)

    return finalTraits.length > 0 ?
      finalTraits.map(this.renderGroupTrait.bind(this, possibleTraits, group)) :
      <div className="no-traits">
        No traits are currently available in this group.<br/>
        Requirements not met.<br/>
        Try selecting things in other groups first.
      </div>
  }

  render() {
    const hashInput: React.RefObject<HTMLInputElement> = React.createRef()
    const possibleTraits = (group: StepGroup) =>
      this.getTraitsForGroup(group.exclude_ids, getAllPossibleTraits(this.props.myTraits));

    function applyFromHashClicked(this: TraitEditor) {
      try {
        const traitsFromFingerprint = INITIAL_MYTRAITS.concat(getTraits(hashInput.current?.value || ""))
        this.props.updateMyTraits(traitsFromFingerprint)
      } catch (e) {
        alert("Error in Fingerprint")
      }
    }

    return (
        <div className="editor">
          <div className="editor-preview">
            <button className="random-btn" onClick={()=>this.randomize()} title="Replace current Fella with a random set of traits"><FontAwesomeIcon icon={faRandom} /> Randomize</button>
            <button className="delete-btn" onClick={()=>this.clear()} title="Start fresh"><FontAwesomeIcon icon={faTrashAlt} /> Clear</button>
            <div className="character-hash">
              <input ref={hashInput} placeholder="Fingerprint"/>
              <button className="hash-btn" onClick={applyFromHashClicked.bind(this)} title="Recreate Fella from fingerprint"><FontAwesomeIcon icon={faSync} /></button>
            </div>
            {this.renderCharacter(this.props.showPopup)}
            { this.props.myTraits.length > 1 &&
              <button className="button-mint" onClick={this.mint.bind(this)} title="Create NFT token in connected wallet">
                Mint <strong>for 0.1 <FontAwesomeIcon icon={faEthereum} /></strong>
              </button>
            }
          </div>
          <div className="newLayout">
            <div className="groups">
              <div className="group-desc">
                <strong>Chose traits from these categories.</strong> A trait only shows up when all required traits are selected.
              </div>
              {stepGroups.map((group, i) => {
                let numPossibleTraitsInGroup = possibleTraits(group).length
                return (
                  <div key={i} className={"group-icon" + (group.id === this.state.selectedGroup.id ? " selected" : "")} title={group.title} onClick={() => this.selectGroup(group)}>
                    { typeof group.icon === 'string' ?
                      svgs[group.icon]() :
                      <FontAwesomeIcon icon={group.icon} />
                    }
                    {numPossibleTraitsInGroup >0 &&
                      <div className="trait-group-count">{numPossibleTraitsInGroup}</div>
                    }
                  </div>
                );
              })}
            </div>
            <div className="group-traits">
              <div className="group-traits-content">
                {this.renderGroupTraits(this.state.selectedGroup)}
              </div>
            </div>
          </div>
        </div>
    );
  }

}

export default TraitEditor
export {
  toggleTrait,
  getIconPath,
  INITIAL_MYTRAITS
}
import * as React from "react";

function SvgTorso(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M207.56 516.51v-12.77a78.88 78.88 0 0178.8-78.74h212.35c-.65.61-1.31 1.22-2 1.85q-5.16 5-9.85 10.35h-200.5a66.79 66.79 0 00-66.6 66.59V681.6a66.79 66.79 0 0066.6 66.59h12.76a66.79 66.79 0 0066.59-66.59v-98.5h80.71l1.58 12.21h-70.08v86.29a78.88 78.88 0 01-78.8 78.79h-12.76a78.88 78.88 0 01-78.8-78.79zm415.35 638.9h-245v130.79a78.89 78.89 0 01-78.8 78.8h-12.75a78.89 78.89 0 01-78.8-78.8v-222.35a78.89 78.89 0 0178.8-78.8H498.1a100.44 100.44 0 003.68 12.21H286.36a66.79 66.79 0 00-66.6 66.59v222.35a66.79 66.79 0 0066.6 66.59h12.76a66.78 66.78 0 0066.59-66.59v-143h257.2a66 66 0 0015.69-1.9 99.89 99.89 0 008.62 10.23 78.18 78.18 0 01-24.31 3.88zm158.94-24.08h-35.44v-.07h-28.76a49.9 49.9 0 01-47.65-35.85 126.25 126.25 0 00-88.34-85.41 49.56 49.56 0 01-36.51-41.69L493 558a48.12 48.12 0 01-.38-7.78c2.26-67.19 57.91-121.45 125.63-121.45h134.84v.07H881.3c67.71 0 123.37 54.27 125.62 121.46a48.18 48.18 0 01-.38 7.78l-52.2 410.33a49.56 49.56 0 01-36.51 41.69 126.2 126.2 0 00-88.32 85.36 49.9 49.9 0 01-47.66 35.87zm510.59 154.75a78.89 78.89 0 01-78.8 78.8h-12.76a78.89 78.89 0 01-78.8-78.8v-130.79h-245a78.25 78.25 0 01-22.15-3.21 100.11 100.11 0 008.84-10.36 65.61 65.61 0 0013.31 1.37h257.2v143a66.78 66.78 0 0066.59 66.59h12.76a66.79 66.79 0 0066.6-66.59v-222.36a66.79 66.79 0 00-66.6-66.59h-212.71a100.32 100.32 0 003.65-12.21h209.07a78.89 78.89 0 0178.8 78.8zm0-769.69v165.09a78.88 78.88 0 01-78.8 78.79h-12.76a78.88 78.88 0 01-78.8-78.79v-86.29h-67.4l1.55-12.21h78.06v98.5a66.79 66.79 0 0066.59 66.59h12.76a66.79 66.79 0 0066.6-66.59V503.62a66.79 66.79 0 00-66.6-66.59h-198.08q-4.63-5.23-9.69-10.16c-.71-.69-1.44-1.37-2.16-2h209.93a78.88 78.88 0 0178.8 78.79zM749.93 147.21a120.21 120.21 0 11-85 35.21 119.43 119.43 0 0185-35.21m0-12.21a132.44 132.44 0 10132.43 132.44A132.43 132.43 0 00749.93 135z" />
    </svg>
  );
}

export default SvgTorso;

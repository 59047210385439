import React, {RefObject} from "react";
import TraitEditor, {getIconPath, INITIAL_MYTRAITS, toggleTrait} from "./Traits";
import Stats from "./Stats";
import Examples from "./Examples";
import Gallery from "./Gallery";
import "./App.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Web3 from "web3"
import Token from "./contracts/Token.json";
import { AbiItem } from 'web3-utils/types'
import {isDevelopment} from "./trait_utils";
import {getFingerprint, Trait} from "shared";
import {toSimpleTrait} from "shared/src/trait_definitions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Collapsible from 'react-collapsible';
import {faDiscord, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";
import MyFellas from "./MyFellas";


//import 'react-tabs/style/react-tabs.css';

type AppState = {
  web3: Web3,
  connected: boolean,
  cover: RefObject<HTMLDivElement>,
  isSelectedTraitPopupShown: boolean,
  myTraits: Trait[],
  tabIndex: number
}

type AppProps = {}

class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props)
    this.state = {
      connected: false,
      web3: this.createWeb3(),
      cover: React.createRef(),
      isSelectedTraitPopupShown: false,
      myTraits: INITIAL_MYTRAITS,
      tabIndex: 0
    }
  }

  componentDidMount() {
    if (this.state.web3.currentProvider) {
      this.setInitialConnectionState()
    }
  }

  setInitialConnectionState() {
    this.state.web3.eth.getAccounts().then(accounts => {
      if (accounts && accounts[0]) {
        this.setDefaultAccount(accounts[0])
        this.setState({connected: true})
      }
    })
  }

  async connectMetaMask() {
    let ethereum = (window as any).ethereum
    if (ethereum) {
      try {
        let accounts = await ethereum.request({method:'eth_requestAccounts'});
        if (this.state.web3){
          this.state.web3.setProvider(ethereum)
          console.log("Connected to account", accounts[0])
          this.setDefaultAccount(accounts[0])
          this.setState({connected: true})
          this.printBalance()
        } else {
          console.error("No web3 in state. This should never happen")
        }

        ethereum.on('accountsChanged',  (accounts: any) => {
          if (!accounts) {
            console.log("User logged out account in MetaMask")
            this.setState({connected: false})
          } else {
            console.log("Switched to account", accounts[0])
            this.setDefaultAccount(accounts[0])
            this.printBalance()
          }
        })
      } catch(error) {
        console.error("User denied MetaMask connection", error)
      }
    } else {
      alert("No wallet provider found. Install MetaMask or another provider")
    }
  }

  setDefaultAccount(account: string) {
    this.setState(state => {
      state.web3.defaultAccount = account
      return {
        web3: state.web3
      }
    })
  }

  printBalance() {
    let web3 = this.state.web3
    let account = web3.defaultAccount
    if (account)
      web3.eth.getBalance(account, (err, wei) => {
        let balance = this.state.web3.utils.fromWei(wei, 'ether')
        console.log("Wallet balance", balance)
      })
    else console.warn("No account available")
  }

  createWeb3() : Web3 {
    let web3 = new Web3( Web3.givenProvider);
    (window as any).web3 = web3; // make contract available for debugging in console


    const rinkeby1 = "0x77E39136BbBdDA5E9Af3a1b69426775752d0093a"
    const rinkeby2 = "0x5d29FACd15caDd238bEb6DC55Cb58178eD9b7220"
    const local = '0x3Ed6da96Ee6e0FA3943871337bE27Ac5338703A4'
    const main = '0x07015E9728F4B9b97666132D6f2f1c6ccE9FfE70'
    let contractAddress = main

    const contract = new web3.eth.Contract(Token.abi as AbiItem[], contractAddress);
    (window as any).contract = contract; // make contract available for debugging in console

    return web3
  }

  togglePopup() {
    this.setState(state => {return {isSelectedTraitPopupShown: !state.isSelectedTraitPopupShown}})
  }


  handleCopyClicked(fingerprintRef: React.RefObject<HTMLTextAreaElement>, e:any) {
    const copyText = fingerprintRef.current;
    copyText?.select();
    document.execCommand("copy");
  }


  renderSelectedTrait(trait: Trait) {
    const overflowHidden = trait.exclude_ids[0] === "canvas"
    let previewClass = "trait-preview selected" +
        ( overflowHidden ? " cropped" : "")
    let title = !isDevelopment() ? "Remove " + trait.name : JSON.stringify(trait, null, 2)
    const traitClass = "trait-preview-wrapper"

    const removeTrait = () => {
      this.setState(state => {
        return {
          myTraits: toggleTrait(trait, state.myTraits)
        }
      })
    }
    return (
        <div key={trait.trait_id} className={traitClass}>
          <div className={previewClass} onClick={(e)=> {removeTrait(); e.stopPropagation()}}>
            <img src={getIconPath(trait)} alt="" title={title} />
            <div className="trait-action" />
          </div>
          <div className="trait-name">
            {trait.name}
          </div>
        </div>
    );
  }

  updateMyTraits(traits: Trait[]) {
    this.setState({myTraits: traits})
  }

  renderSelectedTraitsPopup() {
    const fingerprintRef: React.RefObject<HTMLTextAreaElement> = React.createRef()
    const fingerprint = getFingerprint(this.state.myTraits.map(toSimpleTrait))
    return (
        <div className="selected-traits-popup" onClick={e => e.stopPropagation()}>
          <div onClick={() => this.togglePopup()} className="close-button"><FontAwesomeIcon icon={faTimesCircle} /></div>
          <div className="fingerprint-wrapper">
            <div className="fingerprint-desc">Fingerprint:</div>
            <textarea className="fingerprint" title={fingerprint.toString()} ref={fingerprintRef} value={fingerprint.toString()} readOnly />
            <button onClick={this.handleCopyClicked.bind(this, fingerprintRef)} title="Copy"><FontAwesomeIcon icon={faCopy} /></button>
          </div>
          <div className="group-traits">
            <div className="group-traits-content selected-traits-content">
              {this.state.myTraits.slice(1).reverse().map((t) => this.renderSelectedTrait(t))}
            </div>
          </div>
        </div>
    )
  }

  renderFaq() {
    return (
        <div className="faq">
          <h1>FAQ</h1>

          <Collapsible trigger="Odd fellas? What the heck?">
            <p>
              The ODD fellas are highly customizable ERC-721 compliant NFTs.
              With the help of the "ODDITOR" you can put together, generate or change your own individual skin.
              The fellas consist of a body to which various limbs can be attached.
              You can equip the basic body with hair, beards, clothes and lots of stuff ... a maximum of 40 traits.
              Every fella is unique, so be quick! Finished? Then mint 'em!
              <br/>
              The verified contract on the ethereum Blockhain is <a href="https://etherscan.io/address/0x07015E9728F4B9b97666132D6f2f1c6ccE9FfE70#code">here</a>
            </p>
          </Collapsible>

          <Collapsible trigger="How many fellas are mintable?">
            <p>
              6000 can be minted by you and an additional 50 can be minted by the creators only to give out in specials.
            </p>
          </Collapsible>

          <Collapsible trigger="What is the price of an odd fella?">
            <p>
              An odd fella costs 0.1 ETH. The gas price for the transaction fluctuates, as you
            </p>
          </Collapsible>

          <Collapsible trigger="How many traits did you paint?">
            <p>
              About 1203 individual pictures were drawn.
            </p>
          </Collapsible>

          <Collapsible trigger="How can I mint / get my own fella?">
            <p>
              You need to have an Ethereum wallet and a compatible Browser or plugin like <a href="https://metamask.io">MetaMask</a>
            </p>
          </Collapsible>

          <Collapsible trigger="You crazy bastardz, how the hell does the odditor work?">
            <p>
              Look no further, we <a onClick={() => this.setState({tabIndex: 1})}>created a page</a> describing it for you.
            </p>
          </Collapsible>

          <Collapsible trigger="Are some of the fellas rare fellas?">
            <p>
              Every minted Fella is unique.
            There are also hidden combinations of traits called sets with different rareness (see next faq entry).
            Additionally there are rumors about unique named Fellas.
            </p>
          </Collapsible>


          <Collapsible trigger="What are Sets?">
            <p>
              Sets are hidden combinations of traits which will be revealed when all Fellas are minted.
              There are common sets which are very likely to be chosen and
              more rare sets which will need some luck or creativity to be discovered.
              E.g. there could be a set called "Skywalker" which is awarded when you have light-saber in both hands.
              These sets will be displayed on platforms like opensea.io along with the percentage of owners that have them.
            </p>
          </Collapsible>

          <Collapsible trigger="I heard about unique named fellas crafted by the CREATORZ...">
            <p>
              While building the project we created so many memorable Fellas when testing that we decided to name a few.
              If you happen to recreate one of them yourself, you will get to see how we called it shown as a unique property.
            </p>
          </Collapsible>

          <Collapsible trigger="Where will my fella be stored in the future?">
            <p>
              Fella pictures will be safely stored on Arweave which is a blockchain solution
              that promises eternal availability of your Fella.
            </p>
          </Collapsible>
          <Collapsible trigger="Who are you?">
          <p>
            See About
          </p>
          </Collapsible>
        </div>
    )
  }

  renderRoadMap() {
    return (
      <div className="milestones">
      <h1>Roadmap</h1>
      <div id="milestones">
        <div className="milestone mright">
          <div className="milestone-percentage">5% minted</div>
          <div className="milestone-description">
            Create your unique piece of art in our Odditor, make a screenshot and post it on Twitter.
            The tweet with the most likes wins the depicted Fella for free!
          </div>
        </div>
        <div className="milestone mleft">
          <div className="milestone-percentage">25% minted</div>
          <div className="milestone-description">
            Build your favorite Fella, make a screenshot and post it on Twitter.
            The 2 tweets with the most retweets win the depicted Fellas for free!
          </div>
        </div>
        <div className="milestone mright">
          <div className="milestone-percentage">50% minted</div>
          <div className="milestone-description">
            We will print out 100 decks of physical collector cards and send them to 100 different owners that participate
            on Discord.
          </div>
        </div>
        <div className="milestone mleft">
          <div className="milestone-percentage">75% minted</div>
          <div className="milestone-description">
            The 3 people owning the most Fellas and participating on Discord will get a special treat:
            They can send a picture of a person and our artist will turn it into a Fella.
          </div>
        </div>
        <div className="milestone mright">
          <div className="milestone-percentage">100% minted</div>
          <div className="milestone-description">
            We will reveal a number of hidden collections describing certain trait sets.
            <br/>
              On trading platforms as opensea.io you can see them, including their
              rarity based on how many others happened to chose the required set of traits.
          </div>
        </div>
      </div>
      <div id="fella">
        <img src="images/half_fella_01_big.png" alt="fella" />
      </div>
      </div>
    )
  }

  render() {
    const setTabIndex = (index: number) => {
      this.setState({tabIndex: index})
    }
    let isLoggedIn = this.state.connected
    return (
      <>
        <div className="top-section"/>
        <Tabs className="main" selectedIndex={this.state.tabIndex} onSelect={i => setTabIndex(i)}>
          <TabList className="top">
            <Tab id="first">Odditor</Tab>
            <Tab>HowTo</Tab>
            <Tab>Gallery</Tab>
            {isDevelopment() &&
                <>
                  <Tab>Examples</Tab>
                  <Tab>Stats</Tab>
                </>
            }
            <Tab className="top-right">About</Tab>
            {!isLoggedIn &&
              <button className="top-right" disabled={isLoggedIn} onClick={this.connectMetaMask.bind(this)}>
                {isLoggedIn ? "✔️ Wallet Connected" : "Connect"}
              </button>
            }
            {isLoggedIn &&
              <Tab>My Fella(s)</Tab>
            }

          </TabList>

          <div className="top-center">
            <img id="logo" src="images/logo.png" alt="logo" />
          </div>

          <TabPanel className="content">
            <div id="teaser">
              <img src="/images/welcome.png" alt="welcome" />
              <div id="intro">
                <h2>
                  Unlock the swagness - unleash the wackness and enter the twilight zone of controlled madness. Dip in, throw up or just get lost.
                  Craft your unique Fella in the "Odditor" and mint it as a <strong>Collectible NFT token</strong>.
                </h2>
              </div>
            </div>
            <TraitEditor showPopup={this.togglePopup.bind(this)} updateMyTraits={this.updateMyTraits.bind(this)} myTraits={this.state.myTraits}/>
            {this.renderFaq()}
            {this.renderRoadMap()}
          </TabPanel>
          <TabPanel className="howto">
            <img src="/images/howto.png" />
          </TabPanel>
          <TabPanel className="content gallery-content">
            <Gallery />
          </TabPanel>
          {isDevelopment() &&
              <>
                <TabPanel className="content gallery-content">
                  <Examples/>
                </TabPanel>
                <TabPanel className="content">
                  <h2>Dev</h2>
                  <Stats/>
                </TabPanel>
              </>
          }
          <TabPanel className="about content">
            <h1>Who created this <strong>oddness</strong>?</h1>
            <div className="about-text">
              We are three odd fellas who have fallen head over heels into this madness out of love for NFTs and creative projects.
              For months, our families and friends have suffered from our constant distraction and countless night shifts.
              Unfortunately nice. We had an editor for collectible character NFTs in mind that is easy to use and delivers a result
              that puts a smile on our faces and hopefully on yours too. We wanted it to be memorable, weird and reflect the spirit of an
              unleashed, free and global population.
            </div>
            <div className="about-fellas">
              <div className="about-fella-wrapper">
                <img src="images/about/ogfs-01.png" alt="Fella Creator 1"/>
              </div>
              <div className="about-fella-wrapper">
                <img src="images/about/ogfs-02.png" alt="Fella Creator 2"/>
              </div>
              <div className="about-fella-wrapper">
                <img src="images/about/ogfs-03.png" alt="Fella Creator 3"/>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <MyFellas/>
          </TabPanel>
          <div className="social-media">
            <a href="https://discord.gg/ybyvMtKJKT" target="_blank"><FontAwesomeIcon icon={faDiscord} /></a>
            <a href="https://twitter.com/fellas_odd" target="_blank"><FontAwesomeIcon icon={faTwitterSquare} /></a>
          </div>
          <div className="legal">
            <a href="terms.html">Terms and Conditions</a>
            <a href="privacy.html">Privacy</a>
          </div>
        </Tabs>
        {this.state.isSelectedTraitPopupShown &&
          <div ref={this.state.cover} id="cover" onClick={() => this.togglePopup()}>
            {this.renderSelectedTraitsPopup()}
          </div>
        }
        </>
    );
  }
}

export default App;

import React from "react";
import {generatePermutation} from './trait_utils';
import Character from "./Character";
import {Trait} from 'shared';


class Examples extends React.Component {

  // returns an array of characters(trait arrays)
  getRandomPermutations(amount: number): Trait[][]{
    let result = []
    while (result.length < amount) {
      result.push(generatePermutation());
    }
    return result;
  }

  render() {
    return (
      <div className="gallery">
        {this.getRandomPermutations(20).map((traits, index) =>
            <Character key={index} traits={traits} />
        )}
      </div>
    );
  }
}

export default Examples

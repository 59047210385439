import * as React from "react";

function SvgSlips(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M1335.12 568.26l-6 47.22a165.53 165.53 0 01-7.42 32.15c-17.56 52-17.17 61.3-71.63 75.64-140.9 37.11-296.32 146.28-338.07 285.26a172.7 172.7 0 01-9.18 23.85c-27 57.1-83.77 95.18-148.2 95.9h-1.92 1.88v-.21h-7.31c-67.5 0-126.87-41-152.83-102.06a173 173 0 01-6.43-17.73C546.22 869.3 390.8 760.13 249.91 723c-54.23-14.29-53.71-23.29-71.41-75a165.7 165.7 0 01-7.64-32.8l-6-47a62.67 62.67 0 0162.17-70.58H1273a62.67 62.67 0 0162.12 70.64z" />
    </svg>
  );
}

export default SvgSlips;

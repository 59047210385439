import * as React from "react";

function SvgFaceAttachment(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M1362.19 772.15l-97-388.82a188.17 188.17 0 00-242.2-133l-32.63 10.87a34.23 34.23 0 00-21.66 43.31L979.49 337a34.23 34.23 0 0043.29 21.65l28.2-9.39c23.26-7.75 49.22-7.64 71 3.7a84.78 84.78 0 0143.58 55.25l82.3 329.14c-47.48-14.57-106.54-26.66-173.75-26.66-74.4 0-158.3 15-245.75 57.22H671.73C584.27 725.7 500.37 710.7 426 710.7c-67.23 0-126.29 12.09-173.77 26.66l82.3-329.16a84.74 84.74 0 0143.55-55.2c21.74-11.34 47.68-11.45 70.94-3.7l28.18 9.39A34.23 34.23 0 00520.49 337l10.82-32.48a34.21 34.21 0 00-21.65-43.31L477 250.35a188.18 188.18 0 00-242.2 132.94l-97 388.86a137.77 137.77 0 00-4.07 33.15v150.32c0 134.59 110.37 243.7 246.51 243.7h79.42c129 0 236.17-98.3 245.82-225.47l6.27-82.66h76.5l6.27 82.66c9.63 127.17 116.81 225.47 245.8 225.47h79.42c136.14 0 246.51-109.11 246.51-243.68V805.32a137.31 137.31 0 00-4.06-33.17z" />
    </svg>
  );
}

export default SvgFaceAttachment;

import * as React from "react";

function SvgPants(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M1287.17 1202.59l-222.52 65.78a42.07 42.07 0 01-52.27-28.42l-211-713.88a82.13 82.13 0 01-51.72 19.09h-.93.93v-.11H746a81.94 81.94 0 01-47.94-15.46L488 1240.31a42.07 42.07 0 01-52.27 28.41L213.17 1203a42.07 42.07 0 01-28.41-52.27L448 260.18a42.07 42.07 0 0145-29.88h511.81a42.09 42.09 0 0147.52 29.52l263.23 890.51a42.05 42.05 0 01-28.39 52.26z" />
    </svg>
  );
}

export default SvgPants;

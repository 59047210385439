import React, {useEffect, useState} from "react";
import "./MyFella.css";
import Web3 from "web3";
import {getTraits, Trait} from "shared";
import Character from "./Character";
import sha256 from 'crypto-js/sha256';

type Fella = {
    tokenId: string,
    traits: Trait[]
}

async function getMintedIdsOf(owner: string) {
    let contract = (window as any).contract;
    let balance = await contract.methods.balanceOf(owner).call()

    console.log("User owns Fellas: " + balance)
    let mintedIds = []
    for (let i=0; i<balance; i++) {
        try {
            const tokenId = await contract.methods.tokenOfOwnerByIndex(owner, i).call()
            mintedIds.push(tokenId)
        } catch (e) {
            console.error("Error when trying to get nth Fella: " + i, e)
            break;
        }
    }
    return mintedIds;
}

async function getMyTraits(): Promise<Fella[]> {
    let web3: Web3 = (window as any).web3;
    let contract = (window as any).contract;

    if (web3.defaultAccount) {
        let ids = await getMintedIdsOf(web3.defaultAccount)

        const fingerprints: Array<{ tokenId: string, fingerprint: bigint }> = await Promise.all(ids.map(async (tokenId: string) => {
            let answer = await contract.methods.getTraits(tokenId).call()
            return {
                tokenId: tokenId,
                fingerprint: BigInt(answer)
            }
        }))

        return fingerprints.map(f => {
            return {
                tokenId: f.tokenId,
                traits: getTraits(f.fingerprint.toString())
            }
        })
    }
    else return []
}

function MyFella() {
    const [myFellas, setMyFellas] = useState([] as Fella[]);

    useEffect(() => {
        const fetchData = async () => {
            const myFellas = await getMyTraits();
            setMyFellas(myFellas);
        };

        fetchData();
    }, []);

    return <div id="myFellas">
        {myFellas.map((fella: Fella) =>
            <div className="myFella">
              <Character traits={fella.traits}/>
                <div className="myFella-desc">
                    <a href={"images/metadata/" + sha256('OddFellas' + fella.tokenId) + '_' + fella.tokenId + '.png'}>Download High-Res</a>
                </div>
            </div>
        )}
    </div>
}

export default MyFella;
import * as React from "react";

function SvgFun(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M607.34 293.5c-53.31 0-97.54 36.56-110.2 85.95-1.42 0-2.49-.36-3.92-.36a114.24 114.24 0 00-114.13 114.13 104.66 104.66 0 003 25 142.66 142.66 0 00-88.63 132c0 22.47 5.71 43.33 14.8 62.23-42.8 24.61-71.86 70.26-71.86 123.22a142.65 142.65 0 0088.09 131.78 130.39 130.39 0 00-2.5 25.15 128.31 128.31 0 00128.39 128.39 121.66 121.66 0 0021.4-2.14c17.12 50.82 64.55 87.73 121.26 87.73a128.31 128.31 0 00128.39-128.39V407.62A114.23 114.23 0 00607.34 293.5zm656.22 542.09c0-53-29.06-98.61-71.86-123.22a140.36 140.36 0 0014.8-62.23 142.66 142.66 0 00-88.63-132 113 113 0 00-111.09-139.09c-1.43 0-2.67.36-3.92.36-12.66-49.35-56.86-85.91-110.2-85.91a114.12 114.12 0 00-114.13 114.12v670.49a128.31 128.31 0 00128.39 128.39c56.71 0 104.14-36.91 121.26-87.73a121.66 121.66 0 0021.4 2.14A128.31 128.31 0 001178 992.52a130.39 130.39 0 00-2.5-25.15 142.65 142.65 0 0088.06-131.78z" />
    </svg>
  );
}

export default SvgFun;

import React from "react";
import {collectionDefinitions, traitDefinitions} from 'shared';
import Step from './Step'
import {groupBy} from "shared";
import {getHashStructure} from "shared";


class Stats extends React.Component {

  render() {
    let colorGrouped = groupBy(traitDefinitions, "color_collection");
    let excludeGrouped: any = groupBy(traitDefinitions, "exclude_ids") // indexed by array, so needs any
    let byLayerOfFirstElement = (a:string, b:string) => excludeGrouped[a][0].layer - excludeGrouped[b][0].layer
    let excludeIdGrouped = Object.keys(excludeGrouped)
      .sort(byLayerOfFirstElement)

    let neededBits = getHashStructure()
      .reduce((acc, group) => acc + group.size ,0);

    let numSets = collectionDefinitions.length

    return (
        <div id="stats">
          <dl>
            <dt>Total traits</dt>
            <dd>{traitDefinitions.length}</dd>
            <dt>Total traits without color duplicates</dt>
            <dd>{Object.keys(colorGrouped).length -1  + colorGrouped["undefined"].length}</dd>
            <dt>Total groups</dt>
            <dd>{excludeIdGrouped.length}</dd>
            <dt>Bit size needed</dt>
            <dd>{neededBits}</dd>
            <dt>Number of Sets</dt>
            <dd>{numSets}</dd>
          </dl>
          <h3>Groups</h3>
          <ul>
            {excludeIdGrouped.map(name =>
              <li key={"state-"+name}>
                <Step title={name} traits={excludeGrouped[name]} possibleTraits={excludeGrouped[name]} expanded={true} showLayer={true}
                maxRows={20}/>
              </li>
            )}
          </ul>
        </div>
    );
  }

}

export default Stats

import * as React from "react";

function SvgShoes(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M272.34 625.05A116.54 116.54 0 01250.87 393l58-5.37 21.47 232.09zm94.58-242.83l101.64-38.61c46.3-17.59 93.92-32.68 143.09-40.75 58.89-9.68 118.84-12.22 178-5.13C992.09 322 1071.72 409.89 1079.3 491.89c10.74 116-136.53 188.19-300.38 203.34-63.3 5.86-140.29-11-195.27-40.46-47.69-25.54-86.09-50.55-195.26-40.45zm191.19 801.6a116.55 116.55 0 11-21.47-232.1l58-5.36 21.47 232.09zm547.08-5.23c-56.84 17.82-116.23 26.33-175.9 27.61-49.82 1.07-99.4-5-148.14-13.81l-107-19.3L652.68 941c109.16-10.11 142.32-41.74 184.54-75.6 48.64-39 121.23-69.73 184.53-75.59 163.85-15.15 321.85 28.75 332.59 144.8 7.58 81.99-54.58 182.99-249.15 243.98z" />
    </svg>
  );
}

export default SvgShoes;

import * as React from "react";

function SvgFace(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M750 186.68c-311.19 0-563.32 252.13-563.32 563.32S438.81 1313.32 750 1313.32s563.32-252.13 563.32-563.32S1061.19 186.68 750 186.68zm-236.23 890.41H495.6a72.61 72.61 0 01-72.69-72.69v-18.17h90.86zm0-127.2h-90.86v-18.17A72.61 72.61 0 01495.6 859h18.17zM495.6 641a72.69 72.69 0 1172.68 72.69A72.61 72.61 0 01495.6 641zm163.54 436.12h-109v-90.89h109zm0-127.2h-109V859h109zm145.37 127.2h-109v-90.89h109zm0-127.2h-109V859h109zm145.38 127.2h-109v-90.89h109zm0-127.2h-109V859h109zm-18.17-236.26A72.69 72.69 0 111004.4 641a72.61 72.61 0 01-72.68 72.66zm145.37 290.74a72.61 72.61 0 01-72.69 72.69h-18.17v-90.86h90.86zm0-54.51h-90.86V859h18.17a72.61 72.61 0 0172.69 72.69z" />
    </svg>
  );
}

export default SvgFace;

import React from "react";
import {generatePermutation} from './trait_utils';
import Character from "./Character";
import {Trait} from 'shared';
import { getTraits } from "shared"

type GalleryState = {
  mintedFingerprints: bigint[],
  hadError: boolean
}

type GalleryProps = {

}

function randomSample (arr: any[], num: number){
  return arr
      .map(a => [a,Math.random()])
      .sort((a,b) => {return a[1] < b[1] ? -1 : 1;})
      .slice(0,num)
      .map(a => a[0]);
}

class Gallery extends React.Component<GalleryProps, GalleryState> {

  constructor(props:any) {
    super(props)
    this.state = {
      mintedFingerprints: [],
      hadError: false
    }
  }

  componentDidMount() {
    this.getMinted()
  }

  // returns an array of characters(trait arrays)
  getRandomPermutations(amount: number): Trait[][]{
    let result = []
    while (result.length < amount) {
      result.push(generatePermutation());
    }
    return result;
  }

  async getMinted() {
    let contract = (window as any).contract;

    contract.getPastEvents(
      'Transfer',
      {
        fromBlock: 12634494,
        toBlock: 'latest'
      },
      async (err:any, events:any) => {
        if (err) {
          console.error(err)
          this.setState({hadError: true})
        } else {
          const mintedFingerprints: bigint[] = await Promise.all(events.map(async (e: any) => {
            let answer = await contract.methods.getTraits( e.returnValues.tokenId).call()
            return BigInt(answer)
          }))
          this.setState({
            mintedFingerprints: randomSample(mintedFingerprints, 10)
          })
        }
      })
  }

  render() {

    if (this.state.hadError)
      return <div className="gallery-notconnected">
        <div>Could not get minted Fellas from Blockchain. Please connect with <a href="https://metamask.io/">MetaMask</a></div>
        <div>Meanwhile you can also see the minted Fellas at <a href="https://opensea.io/collection/odd-fellas">https://opensea.io/collection/odd-fellas</a></div>
      </div>
    else if (this.state.mintedFingerprints.length === 0)
      return <div>No Fellas minted yet. You can be the first!</div>
    else
     return (
        <div className="gallery">
          {
            this.state.mintedFingerprints.map(f => getTraits(f.toString())).map((traits, index) =>
                <Character key={index} traits={traits} />
            )
          }
        </div>
      );
  }
}

export default Gallery

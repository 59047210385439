import * as React from "react";

function SvgHats(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M604.84 322.32c28.12 0 53.2 12.05 74.3 28.11a116.88 116.88 0 00141.73 0c21.11-16.2 46.19-28.11 74.3-28.11 116.89 0 177.13 352.44 196.19 467.68-77.85 25.28-188.22 46.39-341.37 46.39S486.55 815.24 408.63 790c19.1-115.32 79.37-467.68 196.21-467.68zm785.72 430.79c-1.46 4.06-155.26 404.57-640.57 404.57S110.87 757.17 109.41 753.11a32.13 32.13 0 0151.71-34.88c2 1.81 205.66 182.42 588.85 182.42 385.33 0 586.68-180.49 588.69-182.3a32.13 32.13 0 0139.66-3 32.13 32.13 0 0112.24 37.76z" />
    </svg>
  );
}

export default SvgHats;

import * as React from "react";

function SvgBackgrounds(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M894.64 809.08A91.78 91.78 0 01803 717.41a18.34 18.34 0 10-36.67 0c0 70.75 57.58 128.34 128.34 128.34a18.34 18.34 0 000-36.67zM293.68 913V489.25h-32.59A97.78 97.78 0 00163.3 587v521.51a97.79 97.79 0 0097.79 97.78h782.26a97.78 97.78 0 0097.78-97.78v-32.6H456.65c-89.86.03-162.97-73.08-162.97-162.91z" />
      <path d="M1238.91 293.68H456.65a97.78 97.78 0 00-97.78 97.78V913a97.78 97.78 0 0097.78 97.78h782.26A97.78 97.78 0 001336.7 913V391.46a97.79 97.79 0 00-97.79-97.78zm-344.27 625.4C783.16 919.08 693 827.81 693 715c0-127.41 114.6-176.53 168.19-357.31 10.32-34.51 57.87-33 67 0C982 539.3 1096.31 587 1096.31 715c0 112.81-90.2 204.08-201.67 204.08z" />
    </svg>
  );
}

export default SvgBackgrounds;

import * as React from "react";

function SvgHands(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M803.1 886.11L750 864l-22.16-53.08a7.13 7.13 0 00-12.74 0L693 864l-53.1 22.14a7.13 7.13 0 000 12.76L693 921l22.16 53.09a7.13 7.13 0 0012.74 0L750 921l53.1-22.12a7.13 7.13 0 000-12.76z" />
      <path d="M327.39 691.66a11.85 11.85 0 01-15.88-5.37l-36.83-88.52h-.13l-88.39-36.71a12 12 0 010-21.39l88.46-36.88h.09l36.9-88.47a11.87 11.87 0 0121.25 0l36.82 88.48h.13l88.36 36.85a12 12 0 010 21.39l-88.33 36.81h-.25l-36.88 88.44a11.81 11.81 0 01-5.32 5.37zm682.32 514.66H657.47a85.66 85.66 0 01-69.14-35.32L364.45 863.37a71.33 71.33 0 11115.34-83.94l42.05 57.91V407.76a57 57 0 01114.08 0V678.7a14.25 14.25 0 0014.26 14.3h28.52a14.25 14.25 0 0014.3-14.3v-328a57 57 0 01114.08 0v328A14.25 14.25 0 00821.3 693h28.52a14.25 14.25 0 0014.26-14.26V407.76a57 57 0 01114.08 0V678.7a14.25 14.25 0 0014.26 14.3h28.52a14.26 14.26 0 0014.26-14.26V550.36a57 57 0 01114.08 0v313.77c0 2.34-.48 4.76-.48 7.13-13.85 6.62-25.85 17.12-32.71 30.91l-.84 1.69-.73 1.79-27.86 66.81-66.83 27.86-1.78.76-1.78.88a68.93 68.93 0 000 123.24l1.78.89 1.78.75 66.83 27.86.12.3c-13.52 30.41-42.78 51.32-77.07 51.32zm310.56-142.64a13 13 0 01-6.56 10.61l-88.32 36.83h-.16l-36.88 88.44a11.85 11.85 0 01-21.25 0l-36.82-88.39h-.13l-88.36-36.84a11.93 11.93 0 010-21.31l88.47-37h.09l36.9-88.47a11.86 11.86 0 0121.24 0l36.87 88.43h.12l88.36 36.84a13 13 0 016.56 10.7z" />
    </svg>
  );
}

export default SvgHands;

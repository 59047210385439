import * as React from "react";

function SvgBodyparts(props) {
  return (
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 1500"
      {...props}
    >
      <path d="M219.76 525.49v-12.77a66.79 66.79 0 0166.6-66.59h209.27a171.2 171.2 0 00-15.36 18.51 167.38 167.38 0 00-31.43 92.2 92.3 92.3 0 00.7 14.61l2.62 20.63h-86.45v98.49a66.79 66.79 0 01-66.59 66.6h-12.76a66.8 66.8 0 01-66.6-66.6zm403.15 626.69h-257.2v143a66.79 66.79 0 01-66.59 66.59h-12.76a66.79 66.79 0 01-66.6-66.59v-222.35a66.79 66.79 0 0166.6-66.59H508.4a92.06 92.06 0 0061.35 53.15 83.45 83.45 0 0158.25 56.35 93.54 93.54 0 0015.14 29.75c.72.94 1.48 1.84 2.23 2.75a65.91 65.91 0 01-22.46 3.94zm657.33 142.88a66.79 66.79 0 01-66.6 66.59h-12.76a66.79 66.79 0 01-66.59-66.59v-143h-257.2a65.93 65.93 0 01-22-3.78 93 93 0 0016.92-32 83.47 83.47 0 0158.27-56.28 92.09 92.09 0 0061.65-53.85h221.71a66.79 66.79 0 0166.6 66.59zm0-769.69v165.08a66.8 66.8 0 01-66.6 66.6h-12.76a66.79 66.79 0 01-66.59-66.6V592h-89l2.6-20.44a92.35 92.35 0 00.7-14.61 167.31 167.31 0 00-31.59-92.52 171.27 171.27 0 00-15.38-18.43h212a66.79 66.79 0 0166.6 66.59zM755.08 150.44a120.23 120.23 0 11-85 35.21 119.45 119.45 0 0185-35.21m0-12.21a132.44 132.44 0 10132.44 132.44 132.44 132.44 0 00-132.44-132.44z" />
      <path d="M759.19 435.59v.07H887.4a124.18 124.18 0 0145.6 8.62 126.39 126.39 0 0156.44 43.9 124.83 124.83 0 0123.56 68.94 50.17 50.17 0 01-.38 7.78l-3.22 25.33-49 385a49.59 49.59 0 01-36.52 41.69 126.45 126.45 0 00-88.32 85.36 50.66 50.66 0 01-9.56 17.92 49.42 49.42 0 01-38 17.93h-35.49v-.07h-28.76a49.23 49.23 0 01-39.43-19.67 50.83 50.83 0 01-8.23-16.18 126.48 126.48 0 00-88.32-85.37 49.61 49.61 0 01-36.52-41.68L502.3 590.35l-3.25-25.52a49.06 49.06 0 01-.37-7.79 124.62 124.62 0 0123.44-68.72 126.35 126.35 0 0156.09-43.92 124.19 124.19 0 0146.09-8.81h134.89m12.21-12.21H624.3a136.44 136.44 0 00-50.6 9.67 138.65 138.65 0 00-61.5 48.15 136.84 136.84 0 00-25.72 75.43 60.63 60.63 0 00.47 9.74l3.24 25.52 48.95 384.81a61.81 61.81 0 0045.52 52 114.17 114.17 0 0179.74 77.07 62.71 62.71 0 0010.21 20.06 61.31 61.31 0 0049.14 24.48h16.55v.08H788a61.59 61.59 0 0047.38-22.39 62.66 62.66 0 0011.92-22.21 114.2 114.2 0 0179.7-77.07 61.76 61.76 0 0045.52-52l49-385 3.22-25.33a61.73 61.73 0 00.47-9.73A136.92 136.92 0 00999.34 481a138.55 138.55 0 00-61.88-48.12 136.36 136.36 0 00-50.06-9.46h-116v-.08z" />
    </svg>
  );
}

export default SvgBodyparts;

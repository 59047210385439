import React from "react";
import {traitDefinitions, Trait} from 'shared';
import "./Step.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, IconDefinition } from '@fortawesome/free-solid-svg-icons'

let VISIBLE_COLS = 4
let ROW_HEIGHT = 140;

type StepProps = {
  overflowHidden: boolean,
  title: string,
  traits: Trait[],
  possibleTraits: Trait[],
  selectedTraits: Trait[],
  icon: IconDefinition,
  onClick: () => void,
  toggleFunction: (toToggle: Trait) => void,
  showLayer: boolean,
  expanded: boolean,
  maxRows: number
}

type StepState = {
  positionY: number
}

class Step extends React.Component<StepProps, StepState> {

  static defaultProps = {
    overflowHidden: false,
    title: "Title",
    traits: traitDefinitions.slice(1,26),
    selectedTraits: [],
    icon: faCoffee,
    onClick: function() {},
    toggleFunction: function() {},
    showLayer: false,
    expanded: false,
    maxRows: 3
  };

  constructor(props: StepProps) {
    super(props);
    this.state = {
      positionY: 0
    }
    this.toTraitPreview = this.toTraitPreview.bind(this)
  }

/*
  static getDerivedStateFromProps(props, state) {
    // to avoid being positionened too far in an empty slot after removal, we limit when rendering anew
    return {
      positionY: Math.max(Step.getMaxPositionY(props.traits), state.positionY),
    }
  }
*/
  componentDidUpdate(prevProps: StepProps) {
    // jump to the beginning if we changed the number of traits in this step
    // to avoid being positionened too far in an empty slot after removal
    if (prevProps.traits.length !== this.props.traits.length) {
      this.setState({positionY: 0})
    }
  }


  getNumRows(numTraits: number) {
    let neededRows = Math.ceil(numTraits / VISIBLE_COLS)
    return Math.min(this.props.maxRows, neededRows)
  }

  scrollPrev() {
    this.setState(state => {
      return {positionY: Math.min(0, state.positionY + this.getNumRows(this.props.traits.length) * ROW_HEIGHT)}
    })
  }

  scrollNext() {
    this.setState(state => {
      return {
        positionY: Math.max(
          this.getMaxPositionY(this.props.traits),
          state.positionY - this.getNumRows(this.props.traits.length) * ROW_HEIGHT)
      }
    })
  }

  getMaxPositionY(traits: Trait[]) {
    return traits.length === 0 ? 0 :
     -Math.floor(traits.length/(VISIBLE_COLS * this.getNumRows(traits.length))) *
      this.getNumRows(traits.length) * ROW_HEIGHT;
  }

  getIconPath(trait: Trait) {
    return trait.path.slice(0, -4) + "_ico.png"
  }

  isSelected(trait: Trait) {
    return this.props.selectedTraits.some((t) => t.trait_id === trait.trait_id)
  }

  toTraitPreview(trait: Trait, index: number) {
    const isSelected = this.isSelected(trait)
    const isPossible: boolean = this.props.possibleTraits.some((p:Trait) => trait.trait_id === p.trait_id)
    let className = "trait-preview" +
      (this.props.overflowHidden ? " cropped" : "") +
      ( isSelected ? " selected" : "")
    let title = isSelected ? "Unselect " + trait.name : JSON.stringify(trait, null, 2)
    const traitClass = "trait-preview-wrapper" + (isPossible ? "" : " impossible")
    return (
      <div className={traitClass}>
        <div key={index} className={className} onClick={()=>this.props.toggleFunction(trait)}>
          <img src={this.getIconPath(trait)} alt="" title={title}/>
          {this.props.showLayer &&
          <span className="layer">{trait.layer}</span>
          }
        </div>
        <div className="name">{trait.name}</div>
      </div>
    );
  }

  renderStepContent() {
    let leftArrowVisibility = this.state.positionY === 0 ? " disabled" : "";
    let rightArrowVisibility = this.state.positionY <= this.getMaxPositionY(this.props.traits) ? " disabled" : "";
    let isEmpty = this.props.traits.length === 0

    return (
      <div className="step-content">
        <div className="step-content-top">
            <div className={"arrow step-content-left-arrow" + leftArrowVisibility} onClick={()=>this.scrollPrev()}>&lt;</div>
            <div className="trait-wrapper">
              {isEmpty &&
                <div className="step-content-empty">
                  You do not have any of the traits equipped that are necessary for traits in this catgeory
                </div>
              }
              <div className="step-content-traits" style={{top: this.state.positionY + "px"}}>
                {this.props.traits.map(this.toTraitPreview)}
              </div>
            </div>
            <div className={"arrow step-content-right-arrow" + rightArrowVisibility} onClick={()=>this.scrollNext()}>&gt;</div>
        </div>
        <div className="step-content-bottom">
          {this.props.selectedTraits.map(this.toTraitPreview)}
        </div>
      </div>
    );
  }

  render() {
    let stepEmptyClass = this.props.selectedTraits.length === 0 ? " empty" : ""
    return (
        <div className="step">
          <div className={"step-header"+ stepEmptyClass} onClick={() => this.props.onClick()}>
            <div className="step-icon">
                <FontAwesomeIcon icon={this.props.icon} />
            </div>
            <div className="step-title">
              {this.props.title}
              <span className="step-available-count">
                ({this.props.traits.length})
              </span>
            </div>
            <span className="step-selected-count">
              {this.props.selectedTraits.length} selected
            </span>
          </div>
          {this.props.expanded && this.renderStepContent() }
        </div>
    );
  }
}

export default Step

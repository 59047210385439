import React from "react";
import {groupBy} from "shared";
import {getFingerprint} from "shared"
import { Trait } from "shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toSimpleTrait } from "shared/src/trait_definitions";
import { Collection, getCollections } from "shared";
import { isDevelopment } from "./trait_utils";
import mergeImages from 'merge-images';

type CharacterProps = {
  traits: Trait[],
  showLayerSlider?: boolean
  showFingerprint?: boolean
}

type CharacterState = {
  // amount of visible layers from the bottom
  visibleLayers: number,
  base64Image: string
}

class Character extends React.Component<CharacterProps, CharacterState> {

  constructor(props: CharacterProps) {
    super(props)
    this.state = {
      visibleLayers: 100,
      base64Image: ""
    }
  }

  componentDidUpdate(prevProps: CharacterProps, prevState: CharacterState){
    // always move layer slider to the end when trait amount changes
    const traitsAmountChanged = this.props.traits.length !== prevProps.traits.length
    if (traitsAmountChanged)
     this.setVisibilityLayerToMaxPlusOne()
     if (isDevelopment()) {
       this.getBase64Image()
     }
  }

  setVisibilityLayer(layer: number) {
    this.setState(state => {
      let groupedByLayer = groupBy(this.props.traits, "layer")
      let sortedLayerIndexes = Object.keys(groupedByLayer)
        .concat(layer.toString())
        .sort((a,b) => parseInt(a)-parseInt(b))
      let numLayer = sortedLayerIndexes.findIndex(i => layer === parseInt(i))
      let numberOfLayers = sortedLayerIndexes.length

      let newLayerIndex =  state.visibleLayers
      let isAtMax = state.visibleLayers === numberOfLayers-1 || state.visibleLayers === 100
      if (!isAtMax && numLayer > state.visibleLayers) {
        newLayerIndex = numLayer +1
      }
      return {visibleLayers: newLayerIndex}
    });
  }

  setVisibilityLayerToMaxPlusOne() {
    this.setState(state => {
      let groupedByLayer = groupBy(this.props.traits, "layer")
      let numberOfLayers = Object.keys(groupedByLayer).length
      return {visibleLayers: numberOfLayers + 1}
    });
  }

  layerVisibilityChanged(e:React.ChangeEvent<HTMLInputElement>) {
    let val = e.target.value
    this.setState({visibleLayers: parseInt(val)})
  }

  handleCopyClicked(fingerprintRef: React.RefObject<HTMLTextAreaElement>, e:any) {
    var copyText = fingerprintRef.current;
    copyText?.select();
    document.execCommand("copy");
  }

  async getBase64Image() {
    let base64 = await mergeImages(
        this.props.traits
            .sort((t1, t2) => parseInt(t1.layer) - parseInt(t2.layer))
            .map(t => t.path)
    )
    this.setState({base64Image: base64})
  }

  render() {
    let showLayerSlider = this.props.showLayerSlider !== false // only if set to false, show it when unset
    let showFingerprint = this.props.showFingerprint !== false // only if set to false, show it when unset
    let groupedByLayer = groupBy(this.props.traits, "layer")
    let sortedLayerIndexes = Object.keys(groupedByLayer).sort((a,b) => parseInt(a)-parseInt(b))
    let numberOfLayers = sortedLayerIndexes.length
    let fingerprint = getFingerprint(this.props.traits.map(toSimpleTrait))
    let fingerprintRef = React.createRef<HTMLTextAreaElement>()
    if (isDevelopment()) {
      console.log(this.props.traits.map(toSimpleTrait).map( t => t.trait_id).join("\",\""))
    }
    return (
      <div>
        <div className="image-wrapper">
          {sortedLayerIndexes.slice(0, this.state.visibleLayers).flatMap(index => groupedByLayer[index]).map((t: Trait) => {
            return <img key={t.trait_id} src={t.path} alt="character element" data-layer={t.layer}/>
          })}
        </div>
        { showFingerprint &&
          <div className="fingerprint-wrapper">
            <textarea className="fingerprint" title={fingerprint.toString()} ref={fingerprintRef} value={fingerprint.toString()} readOnly />
            <button onClick={this.handleCopyClicked.bind(this, fingerprintRef)} title="Copy"><FontAwesomeIcon icon={faCopy} /></button>
          </div>
        }
        {isDevelopment() &&
          <div className="collections">
            {getCollections(this.props.traits).map((collection: Collection) =>
              <div key={collection.name}>{collection.name}</div>
            )}
          </div>
        }
        {showLayerSlider &&
          <div className="layer-slider" title="Pull to strip naked" onClick={(e) => e.stopPropagation()}>
               <input type="range" min="2" max={numberOfLayers} value={this.state.visibleLayers} onChange={(e) => this.layerVisibilityChanged(e)}/>
          </div>
        }
        {isDevelopment() &&
          <a download={fingerprint + ".png"} href={this.state.base64Image} onClick={(e) => e.stopPropagation()}>Download</a>
        }
      </div>
    );
  }
}

export default Character
